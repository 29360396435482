$background-color: #fff;
.light {
  ::selection {
    color: #000;
    background: #ccc;
  }
  .ant-layout-header.header {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
  }
  .drawer-content-wrapper {
    background: $background-color !important;
  }
  .ant-layout {
    background: $background-color;
  }
  .ant-layout-content {
    background: $background-color;
  }
  table,
  .table {
    color: #212529;
    tr.selected-row {
      background: #eee;
    }
    th,
    td {
      border-top: 1px solid #dee2e6;
    }
    th {
      border-bottom: 2px solid #dee2e6;
    }
  }
  .dictionary-editable-desc[contenteditable="true"]:empty:before {
    color: #aaa !important;
  }
  //--UTILITY CLASSES--//
  .utility-underline {
    color: black;
    &:hover {
      color: black;
      text-decoration: underline;
    }
  }

  .help-btn {
    .ant-btn-text:focus,
    .ant-btn-text:hover {
      background-color: white;
    }
  }

  .open-to-metis-underline {
    position: relative;
    z-index: 5;
    &:hover {
      text-decoration: underline;
    }
  }

  .directory-anchor-links {
    background: #fff;
  }
  .app-title-links {
    color: #000;
  }

  .isInactive {
    color: black !important;
  }
  .app-type {
    border: 1px solid #eee;
    font-size: 11px;
    position: absolute;
    right: 12px;
    padding: 0 4px;
    border-radius: 4px;
    background: #f9f9f9;
  }
  .app-icon {
    background: #f9f9f9;
    padding: 8px;
    border-radius: 4px;
  }
  .directory {
    .apps-nav-header {
      .ant-input-affix-wrapper {
        input[type="search"] {
          color: #000;
          &::placeholder {
            color: #ccc;
          }
        }
      }
    }
  }
}

// @import "~bootstrap/scss/bootstrap";
// @import "antd/dist/antd.min.css";
@import "antd/dist/reset.css";
@import "variables";
@import "tables";
@import "dark";
@import "light";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.ant-layout-header.header {
  height: 48px;
  padding: 12px 50px;
  line-height: 48px;

  .logo {
    // float: left;
    // width: 172px;
    // height: 26px;
    // margin: 0;
    // padding: 0 20px;
    // height: 100%;
    padding-right: 10px;
  }
}

.font-weight-normal {
  font-weight: $font-weight-400 !important;
}

.text-light--custom {
  color: $gray-300;
}

.bg-gray-100 {
  background: $gray-100 !important;
}
/* css applied to whole html & body */
*,
*:focus {
  outline: 0 !important;
  // box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}
.btn:focus,
.btn.focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}
body {
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
  overflow: hidden;
  position: relative;
  font-weight: $font-weight-400;
}

// new ant theme css
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
.logo {
  // float: left;
  // margin: 0;
  // padding: 0 20px;
  // height: 100%;
  // a.ant-dropdown-trigger {
  //   height: inherit;
  //   display: flex;
  //   margin: auto;
  // }
  padding: "20px";
  display: flex;
  // img {
  //   filter: invert(100);
  // }
}

.user-info-outer {
  display: flex;
  height: 100%;
  align-items: center;
  order: 10;
  margin-left: auto;
  .user-info-dropdown {
    .sb-avatar {
      vertical-align: unset !important;
    }
  }
}

.not-found {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100%;
}
.site-layout-content {
  min-height: 280px;
  padding: 24px;
}
.ant-tag {
  border-radius: 4px !important;
}
.ant-tag-close-icon {
  top: 1px;
  position: relative;
}
// .ant-typography p, div.ant-typography {
//   margin-bottom: 0 !important;
// }
.search-bar {
  max-width: 200px;
  display: inline-block;
}
.anticon {
  vertical-align: 0 !important;
}
// end
.pointer {
  cursor: pointer;
}
.no-pointer-events {
  pointer-events: none;
}
.drawer {
  z-index: inherit !important;
}
.ant-select-multiple .ant-select-selection-item-remove {
  font-size: 10px !important;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: 1px !important;
}
.ant-select-multiple .ant-select-selection-item {
  font-weight: $font-weight-400 !important;
  font-size: 12px !important;
  padding: 0px 10px;
  line-height: 17px;
  height: auto;
  // border: 1px solid #ccc !important;
  height: 20px;
  white-space: nowrap;
  // background: rgba(148, 148, 170, 0.233) !important;
  // border-radius: 4px !important;
}
.ant-breadcrumb {
  font-size: 12px;
}
.ant-switch-inner {
  font-size: 11px;
}
.ant-select-item {
  font-size: 12px !important;
}

* .table.sticky .header,
* .table.sticky .footer {
  z-index: auto !important;
}
.ant-input {
  font-size: 14px;
}
// entity header
.ant-typography.ant-typography-edit-content.entity-title {
  padding: 10px 0;
}
.ant-typography.ant-typography-edit-content.entity-description {
  margin: 0px;
}
.anticon.anticon-enter.ant-typography-edit-content-confirm {
  display: none;
}
.ant-tooltip {
  font-size: 14px;
}
// directory
.directory {
  h6 {
    font-size: 18px;
  }
  .apps-nav-header {
    .ant-space-item:last-child {
      margin-left: auto;
    }
    .ant-input-affix-wrapper {
      border-radius: 50px !important;
      padding: 2px 16px;
      align-items: center;
      display: flex;
      input[type="search"] {
        background: transparent;
        text-align: left;
        font-size: 16px;
        &::placeholder {
          font-size: 14px;
          font-weight: $font-weight-400;
          font-style: italic;
        }
      }
      .ant-input-clear-icon {
        display: flex;
      }
    }
  }

  .disabled {
    // filter: grayscale(100%);
    pointer-events: none;
    cursor: default;
    // opacity: 0.5;
  }
  .table td,
  .table th {
    border-top: 1px solid transparent;
  }
}

.sb-avatar__image {
  vertical-align: unset;
}

.timeline-View {
  margin-left: -50%;
  padding: 10px;
  margin-top: 30px;
}

.help-btn {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 4px;
  // margin-top: 8px; // if we remove import of reset.css from this file have to enable for style
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background-color: white;
  }
}
.ant-picker-panel-container {
  width: max-content !important;
}

.bg-disabled {
  td {
    opacity: 0.3;
    pointer-events: none;
  }
}

.div-bg-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.container-layout {
  padding-top: 64px !important;
}

.header.container {
  top: 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  padding: 12px 0px;
  height: 64px;
  line-height: 48px;
  display: flex;
  align-items: center;
}
.app-title-links {
  font-weight: 500;
}

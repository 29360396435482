$background-color: #000;
$border-color: #434343;

.dark {
  ::selection {
    color: #fff;
    background: #444;
  }
  .ant-layout-header.header {
    background-color: #1f1f1f;
    border-bottom: 1px solid $border-color;

    .logo {
      filter: invert(100);
    }
  }

  .drawer-content-wrapper {
    background: rgb(27, 27, 27) !important;
  }
  .ant-layout-content {
    background: $background-color;
  }
  table,
  .table {
    color: hsla(0, 0%, 100%, 0.85);
    background: #111;
    tr.selected-row {
      background: #222;
    }
    th,
    td {
      border-top: 1px solid #3a3a3a;
    }
    th {
      border-bottom: 2px solid #3a3a3a;
    }
  }
  .dictionary-editable-desc[contenteditable="true"]:empty:before {
    color: #999 !important;
  }
  .modal-content {
    background-color: #111;
    border: 1px solid rgba(0, 0, 0, 0.2);
    .modal-header {
      border-bottom: 1px solid #333;
    }
  }
  .ant-select-selector {
    background-color: #111111 !important;
  }

  .utility-underline {
    color: white;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }

  .docs-logo {
    filter: invert(1);
  }

  .help-btn {
    &__icon {
      filter: invert(1);
    }
    .ant-btn-text:focus,
    .ant-btn-text:hover,
    .ant-btn-text:active {
      background-color: #1f1f1f;
    }
  }

  .open-to-metis-underline {
    position: relative;
    z-index: 5;
    img {
      opacity: 0.6;
      filter: invert(1);
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .directory-anchor-links {
    background: #000;
  }
  .app-title-links {
    color: #fff;
    font-weight: 700;
  }

  .isInactive {
    color: white !important;
  }

  .antd-dark-notification {
    background-color: #1f1f1f !important;
  }

  .ant-notification-notice-message {
    color: hsla(0, 0%, 100%, 0.85) !important;
  }
  .ant-notification-notice-close {
    color: hsla(0, 0%, 100%, 0.85) !important;
  }
  .app-type{
      color: #fff;
      border: 1px solid #333;
      font-size: 11px;
      position: absolute;
      right: 12px;
      padding: 0 4px;
      border-radius: 4px;
      background: #222;
  }
  .app-icon {
    background: #d1d1d1;
    padding: 8px;
    border-radius: 4px;
    filter: invert(1) brightness(1);
  }
  .directory {
    .apps-nav-header {
      .ant-input-affix-wrapper {
        input[type="search"] {
          color: #fff;
          &::placeholder {
            color: #666;
          }
        }
      }
    }
  }
}
